import type { Point2D } from '@sb/routine-runner';

import { SVGOverlay } from './SVGOverlay';

interface ChessboardOverlayProps {
  points: Point2D[] | null;
}

export function ChessboardOverlay({ points }: ChessboardOverlayProps) {
  if (!points) {
    return null;
  }

  return (
    <SVGOverlay>
      {points.map(({ x, y }, index) => {
        const key = `${index}`;

        return (
          <circle
            key={key}
            cx={x}
            cy={y}
            r={6}
            fill="none"
            strokeWidth={index === 0 ? 8 : 4}
            className="tw-stroke-yellow-20/60"
          />
        );
      })}
    </SVGOverlay>
  );
}
