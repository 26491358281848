import { useRef } from 'react';

import { useCameraStreamCanvasSize } from './useCameraStreamCanvasSize';

interface SVGOverlayProps {
  children: React.ReactNode;
}

export function SVGOverlay({ children }: SVGOverlayProps) {
  const svgRef = useRef<SVGSVGElement>(null);

  const canvasSize = useCameraStreamCanvasSize(svgRef);

  return (
    <svg
      ref={svgRef}
      className="tw-absolute tw-inset-0 tw-w-full tw-h-full"
      viewBox={`0 0 ${canvasSize.width} ${canvasSize.height}`}
    >
      {children}
    </svg>
  );
}
