import cx from 'classnames';

import type { Blob2D } from '@sb/routine-runner';

import { SVGOverlay } from './SVGOverlay';

interface Blobs2DOverlayProps {
  blobs: Blob2D[];
  size?: 'default' | 'large';
}

const SIZES = {
  default: { boxStroke: 4, labelRadius: 13, className: 'tw-text-17' },
  large: { boxStroke: 8, labelRadius: 36, className: 'tw-text-[48px]' },
};

export function Blobs2DOverlay({
  blobs,
  size = 'default',
}: Blobs2DOverlayProps) {
  const labelBackgrounds: JSX.Element[] = [];
  const boxes: JSX.Element[] = [];
  const labels: JSX.Element[] = [];

  blobs.forEach(({ x, y, width, height, rotation }, index) => {
    const key = `${index}`;

    // using `unshift` to reverse the list, i.e. paint blob#1 last

    labelBackgrounds.unshift(
      <circle
        key={key}
        cx={x}
        cy={y}
        r={SIZES[size].labelRadius}
        className="tw-fill-dark/60"
      />,
    );

    boxes.unshift(
      <rect
        key={key}
        x={x - width / 2}
        y={y - height / 2}
        width={width}
        height={height}
        fill="none"
        strokeWidth={SIZES[size].boxStroke}
        transform={`rotate(${rotation} ${x} ${y})`}
        className="tw-stroke-yellow-20/60"
      />,
    );

    labels.unshift(
      <text
        key={key}
        x={x}
        y={y}
        alignmentBaseline="central"
        textAnchor="middle"
        className={cx(SIZES[size].className, 'tw-fill-light')}
      >
        {index + 1}
      </text>,
    );
  });

  return (
    <SVGOverlay>
      {labelBackgrounds}
      {boxes}
      {labels}
    </SVGOverlay>
  );
}
